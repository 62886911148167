@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

/* Global */

:root {
  --ff-primary: 'Sora', sans-serif;
  --ff-secondary: sans-serif;
  --ff-heading: "Oswald", sans-serif;

  --fs-xl: clamp(2rem, 3vw + 1rem, 3rem);
  --fs-600: 1.5rem;
  --fs-500: 1.25rem;
  --fs-400: 1rem;

  --clr-black: #000000;
  --clr-white: #ffffff;
  --clr-neutral: #404040;
  --clr-zinc-300: #D4D4D8;
  --clr-zinc-500: #71717A;
  --clr-zinc-800: #27272A;
}

.light-bg {
  background-color: var(--clr-white);
  color: var(--clr-black);
}

.dark-bg {
  background-color: var(--clr-black);
  color: var(--clr-white);
}

h1 {
  font-size: var(--fs-xl);
  font-weight: var(--fs-500);
}

h6 {
  color: var(--clr-zinc-300)
}

p {
  color: var(--clr-zinc-500);
}

a {
  text-decoration: none;
  color: inherit;
}

b {
  font-weight: 800;
}

ul {
  list-style: none;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  font-family: var(--ff-primary);
  margin: 0;
  padding: 0;
}

section > h1 {
  text-align: center;
}

section {
  padding: 3rem;
}

/* Navigation Bar */

.container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--clr-white);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  padding: 0 1.5rem;
}

.cover_show {
  visibility: visible;
  opacity: 0%;
  background-color: var(--clr-black);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  transition: opacity 0.2s;
}

.cover_hide {
  visibility: hidden;
  opacity: 0%;
  background-color: var(--clr-black);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  transition: opacity 0.2s;
}

.content {
  display: grid;
  grid-template-columns: auto auto;

  height: 3.5rem;
  max-width: var(--max-width);
  margin: 0 auto 0;
}

.left {
  justify-self: left;
  align-self: center;
}

.logo {
  width: 1.5rem;
  height: auto;
  align-self: center;
}

.right {
  justify-self: right;
  align-self: center;

  display: grid;
  grid-template-columns: auto auto;

  font-family: Sora;
  font-size: var(--fs-400);
  font-weight: var(--fs-600);
}

.hamburger {
  width: 3rem;
  padding: 1rem;
  margin-right: -1rem;
  display: none;
}

.sections {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(5, auto);
  min-width: 500px;
}

.section {
  transition: color 0.2s;
  align-self: center;
  justify-self: right;
}

@media only screen and (max-width: 600px) {
  .hamburger {
    display: block;
  }

  .menu_show {
    background-color: var(--clr-white);
    width: 100vw;
    height: 3.5rem;
    position: fixed;
    top: 0;
    transition: top 0.25s;
    left: 0;
  }

  .menu_hide {
    background-color: var(--clr-white);
    width: 100vw;
    height: 3.5rem;
    position: fixed;
    top: -3.5rem;
    transition: top 0.25s;
    left: 0;
  }
  .sections {
    position: relative;
    justify-self: center;
    min-width: 0;
    padding: 1rem .5rem;
  }
  .section {
    justify-self: center;
  }
}

.section:hover {
  color: red;
}

.github_icon {
  height: 20px;
  padding-top: 2px;
}

/* Main Section */

main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem 3rem 3rem 3rem;
  align-items: center; 
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

header > p {
  max-width: 100%;
  width: 100%;
}

.image-column {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.hollow {
  font-family: sans-serif;
  color: transparent;
  -webkit-text-stroke: 2px var(--clr-black); 
}

/* Experience */

.job-item ul {
  color: var(--clr-zinc-500);
  list-style: disc;
  padding: 1rem;
  gap: 1rem;
}

.job-item li {
  padding: 5px 0px;
}

.job-item {
  border: 1px solid var(--clr-zinc-500);
  border-radius: 1rem;
  margin: 1.5rem 0;
  flex-basis: calc(100% - 1.5rem);
  padding: 1rem;
  min-width: fit-content;
}

.job-item > button {
  all: inherit;
  border: 0;
  margin: 0;
  flex-basis: 0;
  padding: 0;
}

.job-item:hover, 
.job-item.hover {
  background-color: var(--clr-zinc-800);
}

.job-header {
  display: flex;
  align-items: center;
}

.job-header > img {
  height: 3rem;
  margin-right: 1.5rem;
}

.job-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  width: 100%;
}

.job-position {
  margin: 0;
}

.job-dates {
  margin: 0;
  white-space: nowrap; /* Prevent line breaks in dates */
}

/* Skills */

.box {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.box .color-modified-button {
  flex: 1 1 10rem;
  border: 2px solid black;
  min-width: 10rem;
  background-color: var(--clr-white);
  padding: 1rem;
  margin: 1rem; 
  text-align: center;
  align-self: center;
}

.box p {
  font-weight: 800;
  color: var(--clr-black)
}

.color-modified-button {
  background-color: var(--clr-white);
  padding: 1rem;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.color-modified-button:hover,
.color-modified-button.hover {
  background-color: black;
}

.color-modified-svg {
  transition: filter 0.3s ease-in-out;
}

.color-modified-button:hover .color-modified-svg, 
.color-modified-button.hover .color-modified-svg {
  filter: invert(100%) hue-rotate(180deg);
}

.color-modified-text {
  transition: color 0.3s ease-in-out;
}

.color-modified-button:hover .color-modified-text, 
.color-modified-button.hover .color-modified-text {
  color: var(--clr-white);
}

/* Projects */

.projects li {
  min-width: fit-content;
}

.projects li:nth-child(even) .image-column {
  order: 2;
}

.projects li:nth-child(even) .info-column {
  order: 1;
}

.project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center; 
  margin: 2rem 0;
  padding: 2rem 0;
}

.project > button {
 background-color: inherit;
 color: inherit;
}

.info-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3rem;
}

.info-column h1 {
  margin: 1rem auto 0 auto;
}

.hover-area {
  border-radius: 1rem;
  padding: 1rem;
}

.hover-area:hover,
.hover-area.hover {
  background-color: var(--clr-zinc-800);
}


.color-modified-link {
  transition: color 0.3s ease-in-out; /* Initial color */
}

.color-modified-link:hover {
  color: red;
}

/* Footer */

.footer {
  text-align: center;
}

.footer h3 {
  margin: 0;
  padding: 0 0 1rem 0;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  main {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
  }

  .job-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .job-dates {
    align-self: flex-start;
    margin-top: .5rem;
  }

  .project {
    display: flex;
    flex-direction: column;
  }
  .projects .project .image-column {
    order: 1 !important; /* Ensure image column is on top */
    flex: initial; /* Reset flex to initial for column layout */
  }
  .projects .project .info-column {
    order: 2 !important; /* Ensure info column is below the image column */
    flex: initial; /* Reset flex to initial for column layout */
  }
}
